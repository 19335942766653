/*-----------------------------------
 * MP hayabusa Customize
 * [このファイルの役割]
 * ・プラグインの読み込み
 * ・URLによるモジュールの実行
 * ・サイト設定
 * ・URLによるCSS読み込み設定
 *-----------------------------------*/

import 'jquery.easing'
import './plugins/plugins'
import './plugins/utility'
import INIT from './modules/init'
import TITLE_CUSTOMIZE from './modules/title-customize'

// ページ読み込み
import HOME from './page/home'

/*****
 * サイト設定
 */
window.siteinfo = {
  tagmanager:       'GTM-KX255H5',
  viewport:         'width=1200',
  mapApiKeys: {
    'local':          'AIzaSyCTB3MSMYkTjSz6l30k79XflmmL_FcOxkg', //development
    'www.example.jp': '' //production
  }
}

/*****
 * サイト初期化
 */
INIT()

/*****
 * コンテンツごとのファイル読み込み
 */
// $.require({
//   disableCache:false,
//   '*':{
//     '/': ['/asset/css/home.min.css'],
//   },
// })
switch(location.pathname.replace('main.php','')){
  // トップページ
  case '/': HOME(); break;
  case '/about/':
  case '/service/':
  case '/faq/': TITLE_CUSTOMIZE(location.pathname.replace(/\/([^/]+)\/(main.php)?/,'$1')); break;
}

import VIEWPORTS from './viewports'
import TAGMANAGER from './tagmanager'
import PARTSLOADER from './partsloader'
import CONTENT_TITLE from './contentTitle'

export default function INIT() {
  /*****
   * Promise
   */
  window.load = {
    'header': new $.Deferred,
    'contentFooter': new $.Deferred,
    'footer': new $.Deferred,
    'mapScript': new $.Deferred,
  }

  VIEWPORTS() // Viewportの置き換え
  TAGMANAGER() // GoogleTagManagerの挿入
  
  //header.html, footer.html読み込み
  PARTSLOADER([
    {
      name: 'footer',
      loadhtml: '/asset/html/footer.html',
      target: 'footer #footerNav',
      method:'replaceWith',
      callback(r,_){
        console.info('%c%s%c is loaded','font-weight: bold',r.url,'')
        if( $('[data-plugins="getArticle"][data-content][data-module]').length != 0){
          const $container = $('[data-plugins="getArticle"][data-content][data-module]')
          $.getArticle($container.data()).then(data =>{
            $container.empty()
            $.each(data,(i,item) => $container.append(`<a href="${item.href}" title="${item.title}">${item.title}</a>`) )
          })
        }
      }
    },
    {
      name: 'contentFooter',
      loadhtml: '/asset/html/contentFooter.html',
      target: 'footer,#footer.ui-footer',
      method:'before',
      callback(r,_){
        console.info('%c%s%c is loaded','font-weight: bold',r.url,'');
        $('.homeContentslist').insertBefore('#footer');
      }
    },
    {
      name: 'header',
      loadhtml: '/asset/html/header.html',
      target: 'header',
      method:'replaceWith',
      callback(r,_){
        console.info('%c%s%c is loaded','font-weight: bold',r.url,'')
        // gmenu current
        $('header .p-gnav').currentMenu({mode:1,default:999});
      }
    }
  ])
  // プライバシー・会社概要・サイトマップのコンテンツタイトル
  CONTENT_TITLE({
    'sitemap':'<span>SITEMAP</span><small>サイトマップ</small>',
    'privacy':'<span>PRIVACY POLICY</span><small>プライバシーポリシー</small>',
    'profile':'<span>PROFILE</span><small>会社概要</small>'
  })

  // DOM Ready
  $(() => {
    // <body>にクラス付与
    $.bodyClass();
    // svgスプライト読み込み
    $.loadSVG();
    // htmlデータ読み込み
    $('[data-load-html]').loadHtml();
    // カレントメニュー
    $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});
    // ページングテキスト
    $.pagingText({
      prev:'前のページへ',
      next:'次のページへ',
      list:'一覧'
    })
    // ページトップリンクをスクロール量で表示する
    $.scrollspy({
      trigger:200,
      onBefore(/* pos */) {
        $('.p-gotop__wrap').addClass('hidden');
      },
      onAfter() {
        $('.p-gotop__wrap').removeClass('hidden');
      }
    })
  })

}
// import 'slick-carousel'
export default function HOME() {

  $(() => {
  
    // トップページカスタム
    $.topInit();
  
    // ブロック整形
    (function(__){ $.each(__, $.topBlockCustomizer) }({
      'info':{
        button:{
          disp: true,
          text: '<span>MORE</span><i class="fa fa-angle-right fa-lg"/>',
          attr:{
            class: 'button button-center button-border button-large'
          }
        },
        title:{
          html: '<span>NEWS</span><small>お知らせ</small>' // プレーンテキストで動的にする場合は false
        },
        itemlayout: function($this,$catetitle,$date,$new,$title,$catch,$body){
          $this
            .append( $date )
            .append( $catetitle )
            .append( $title.append($new) )
            // .append( $catch )
            // .append( $body );
            // $date.insertAfter( $catetitle );
        }
      },
      // 'works':{
      //   title:{
      //     html: '<span>WORKS</span><small>施工事例</small>' // プレーンテキストで動的にする場合は false
      //   },
      //   itemlayout: function($this,$catetitle,$date,$new,$title,$catch,$body){
      //     $this
      //       .append( $title.append($new) )
      //       .append( $catetitle )
      //       .append( $date )
      //       .append( $catch )
      //       .append( $body );
      //     // $date.insertAfter( $catetitle );
      //   }
      // },
    }));
  
    /*****
     * Slick実行
     */
    // $('[data-plugin="slider"]').slick({
    //   autoplay:true,
    //   fade: true,
    //   dots: true,
    //   speed: 1200,
    //   autoplaySpeed: 4000,
    //   pauseOnHover: false,
    //   pauseOnFocus: false,
    //   arrows: true
    // })
  
  });
}
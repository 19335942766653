//header.html, footer.html読み込み
export default function PARTSLOADER(config) {

  const w = window;
  $(()=>{
    $.each(config,function(i,__){
      var mes = 'loadhtml error: ';
      $.get(__.loadhtml)
        .done(function(html){
          if($(__.target)[__.method](html).length != 0){ __.callback(this,__) }
          else{
            mes+='Not found DOM $(\'%s\').\nCouldn\'t load \'%s\'';
            console.error(mes,__.target,this.url);
          }
          w.load[__.name].resolve(html)
        }).fail(function(data){ //ajaxの通信に失敗した場合
          mes+='Ajax failed \'%s\'\n%s (%s)';console.error(mes,this.url,data.statusText,data.status);
          w.load[__.name].reject(data)
          return false;
        });
    })
  })

}